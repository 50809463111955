@tailwind base;
@tailwind components;
@tailwind utilities;

// third party styles

@import 'harmonize.css/harmonize.css';
@import 'cookieconsent/build/cookieconsent.min.css';

// base styles

@import './styles/material-icons';
@import './styles/browser-update';
@import './styles/cookie-consent';
@import './styles/legacy_typography';
@import './styles/legacy_utility';

// instance styles

@import 'styles/variable';
@import 'styles/theme';
