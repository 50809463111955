@import 'button';

.buorg {
  background-color: var(--color-body);
  box-shadow: 0 0 0.75em rgb(0 0 0 / 20%);
  border-radius: 5px;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  padding: 1rem;
  max-width: 28rem;
  z-index: 99999;

  .buorg-pad {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &.buorg-fadeout{
    visibility: hidden;
    opacity: 0;
  }

  & #buorgul {
    @include button-primary;
    padding: 0.5rem 1.5rem;
    text-decoration: none;
  }

  & #buorgig {
    @include button-secondary;
    padding: 0.5rem 1.5rem;
    text-decoration: none;
  }
}
