.full-width {
  width: 100%;
}

.default-cursor.default-cursor.default-cursor {
  cursor: default;
}

.prevent-events {
  pointer-events: none;
}

.submit-form-button {
  margin-top: 2rem;
}

.table-actions {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.input-info-wrapper {
  align-items: center;
  display: grid;
  grid-column-gap: 0.3rem;
  grid-template-columns: 1fr minmax(24px, max-content);

  .info-button {
    color: var(--info-icon);
    margin-bottom: calc(1.34375em - 12px);
  }
}

.textarea-info-wrapper,
.checkbox-info-wrapper {
  align-items: flex-start;
  display: grid;
  grid-column-gap: 0.3rem;
  grid-template-columns: 1fr minmax(24px, max-content);

  .info-button {
    color: var(--info-icon);
    margin-top: 0.25em;
  }
}

.hint {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}
