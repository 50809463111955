@import 'button';

.cc-window.cc-window.cc-window {
  background: var(--color-body);
  box-shadow: 0 0 0.75em rgb(0 0 0 / 20%);

  & .cc-compliance.cc-compliance.cc-compliance {
    all: unset;
  }

  & .cc-btn.cc-btn.cc-btn {
    @include button-secondary;
  }
}
