:root {
  // colors

  --color-primary: #f84914;
  --color-secondary: #007a8c;
  --color-background: #ffffff;
  --color-surface: #ffffff;
  --color-body: #fff;
  --color-on-body: #000;

  // fonts

  --font-primary: 'WWTypeWeb', sans-serif;

  // todos
  // this vars have to be renames to "--color" schema like --color-error and --color-on-error (WITHOUT ALIASING)

  --primary: var(--color-primary);
  --primary-pressed: #e03700;
  --primary-hover: #fc7e51;
  --on-primary: #ffffff;
  --secondary: var(--color-secondary);
  --secondary-pressed: #006070;
  --secondary-hover: #268e9e;
  --on-secondary: #ffffff;
  --surface: var(--color-surface);
  --on-surface: #000000;
  --background: var(--color-background);
  --on-background: #000000;
  --error: #e20a16;
  --error-hover: #a60912;
  --error-pressed: #7e070d;
  --on-error: #ffffff;
  --success: #65882a;
  --on-success: #ffffff;
  --text-muted: #878787;
  --text-dark: #878787;

  --table-header: #000000;
  --table-accent: #f0f0f0;

  --control-background: #f0f0f0;
  --info-icon: #878787;
  --outline-border: #f0f0f0;
  --player-toggle-button: #878787;
  --playlist-hover: #ffffff;

  // rename to --height-toolbar and --width-course-card
  --toolbar-height: 65px;

  --media-brand: var(--primary);
  --media-focus: var(--primary);

  --course-card-width: 350px;
}
