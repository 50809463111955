@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 100 700;
  src: url('../assets/fonts/MaterialSymbolsOutlined.woff2') format('woff2-variations');
}

@font-face {
  font-family: 'Material Symbols Sharp';
  font-style: normal;
  font-weight: 100 700;
  src: url('../assets/fonts/MaterialSymbolsSharp.woff2') format('woff2-variations');
}

@font-face {
  font-family: 'Material Symbols Rounded';
  font-style: normal;
  font-weight: 100 700;
  src: url('../assets/fonts/MaterialSymbolsRounded.woff2') format('woff2-variations');
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined', emoji;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-symbols-sharp {
  font-family: 'Material Symbols Sharp', emoji;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-symbols-rounded {
  font-family: 'Material Symbols Rounded', emoji;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.icon-filled {
  font-variation-settings:
    'FILL' 1
}
