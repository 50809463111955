@use '@angular/material' as mat;

@import './_material-base-override';

@import 'material-theme';
@import 'material-override';
@import 'button';

@font-face {
  font-family: WWTypeWeb;
  src:
    url('../assets/fonts/WWTypeWeb-Regular.woff2') format('woff2'),
    url('../assets/fonts/WWTypeWeb-Regular.eot') format('embedded-opentype'),
    url('../assets/fonts/WWTypeWeb-Regular.woff') format('woff'),
    url('../assets/fonts/WWTypeWeb-Regular.ttf') format('truetype');
}

@font-face {
  font-family: WWTypeWeb;
  font-weight: bold;
  src:
    url('../assets/fonts/WWTypeWeb-Bold.woff2') format('woff2'),
    url('../assets/fonts/WWTypeWeb-Bold.woff') format('woff'),
    url('../assets/fonts/WWTypeWeb-Bold.eot') format('embedded-opentype'),
    url('../assets/fonts/WWTypeWeb-Bold.ttf') format('truetype');
}

@include mat.all-component-themes($mat-theme);

body {
  @include material-base-override($mat-theme);
  @include material-override($mat-theme);

  background: var(--color-body);
  color: var(--color-on-body);
  font: 1em var(--font-primary);
  min-height: 100vh;
}
