@mixin material-base-override($mat-theme) {
  .mat-tooltip.mat-tooltip.mat-tooltip.mat-tooltip {
    font-size: 12px;
    max-width: 300px;
  }

  .mat-checkbox-label.mat-checkbox-label.mat-checkbox-label.mat-checkbox-label {
    white-space: break-spaces;
  }

  /* show only one error at once */
  .mat-error.mat-error.mat-error:not(:first-of-type) {
    display: none;
  }

  /* todo
  * should be renamed to mat-icon-success and mat-icon-error to match namespace
  */
  .mat-icon.mat-icon.mat-icon {
    &.icon-success {
      color: var(--success);
    }

    &.icon-error {
      color: var(--error);
    }
  }
}
