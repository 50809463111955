@mixin button {
  border-radius: 0.125rem;
  color: var(--color-body);
  cursor: pointer;
  padding: 0 1.5em;
  transition: background 0.2s;
  line-height: 3em;
  white-space: nowrap;
}

@mixin button-primary {
  @include button;
  background: var(--color-primary);
}

@mixin button-secondary {
  @include button;
  background: var(--color-secondary);
}
