a {
  color: inherit;
  text-decoration: underline;
}

@media only screen and (max-width: 640px) {
  html {
    font-size: 14px;
  }

  p {
    padding: 0 0 1rem 0;
    white-space: pre-line;
  }

  h1 {
    font-size: 1.95rem;
    font-weight: bold;
    line-height: 1.1;
    padding-bottom: 2rem;
  }

  h2 {
    font-size: 1.56rem;
    font-weight: bold;
    line-height: 1.1;
    padding-bottom: 1rem;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.1;
    padding-bottom: 1rem;
  }

  h4 {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.4;
  }

  small {
    font-size: 0.83rem;
    line-height: 1.45;
    padding-bottom: 0.75rem;
  }

  .small {
    font-size: 0.83rem;
    line-height: 1.45;
  }
}

@media only screen and (min-width: 640px) and (max-width: 1032px) {
  html {
    font-size: 16px;
  }

  p {
    padding: 0 0 1rem 0;
  }

  h1 {
    font-size: 2.37rem;
    font-weight: bold;
    line-height: 1.1;
    padding-bottom: 2rem;
  }

  h2 {
    font-size: 1.78rem;
    font-weight: bold;
    line-height: 1.1;
    padding-bottom: 1rem;
  }

  h3 {
    font-size: 1.33rem;
    font-weight: bold;
    line-height: 1.1;
    padding-bottom: 1rem;
  }

  h4 {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.4;
  }

  small {
    font-size: 0.75rem;
    line-height: 1.45;
    padding-bottom: 0.75rem;
  }

  .small {
    font-size: 0.75rem;
    line-height: 1.45;
  }
}

@media only screen and (min-width: 1024px) {
  html {
    font-size: 18px;
  }

  p {
    padding: 0 0 1rem 0;
  }

  h1 {
    font-size: 2.37rem;
    font-weight: bold;
    line-height: 1.1;

    padding-bottom: 2rem;
  }

  h2 {
    font-size: 1.78rem;
    font-weight: bold;
    line-height: 1.1;
    padding-bottom: 1rem;
  }

  h3 {
    font-size: 1.33rem;
    font-weight: bold;
    line-height: 1.1;
    padding-bottom: 1rem;
  }

  h4 {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.4;
  }

  small {
    font-size: 0.75rem;
    line-height: 1.45;
    padding-bottom: 0.75rem;
  }

  .small {
    font-size: 0.75rem;
    line-height: 1.45;
  }
}
